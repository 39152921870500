import {Component, HostListener} from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'toweringAds';

  constructor(private gtmService: GoogleTagManagerService) {

    $(document).ready(() => {
      // On ready
      // Create cookie function
      function createCookie(name, value, days) {
        let expires;
        if (days) {
          const date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = '; expires=' + date.toUTCString();
        } else {
          expires = '';
        }
        document.cookie = name + '=' + value + expires + '; path=/';
      }

      // Reading cookies function
      function readCookie(name) {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
          }
          if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
          }
        }
        return null;
      }

      // Selectors
      const cookiePolicy = document.querySelector('.cookiePolicy');
      const closeCookiePolicy = cookiePolicy.querySelector('.cookiePolicy__close');
      const hasCookie = readCookie('visited');

      // If the 'visited' cookie isn't set, show the popunder after 2 seconds
      if (!hasCookie) {
        setTimeout(() => {
          cookiePolicy.classList.add('is-active');
        }, 2000);
      } else {
        cookiePolicy.parentNode.removeChild(cookiePolicy);
      }

      // On clicking the popunder, hide it and set the cookie so we don't show it until 365 days
      cookiePolicy.addEventListener('click', (e) => {
        e.preventDefault();
        cookiePolicy.classList.remove('is-active');
        cookiePolicy.parentNode.removeChild(cookiePolicy);
        createCookie('visited', true, 365);
      });
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  userExit(){
    this.gtmService.pushTag({
      event: 'user_exit_trigger'
    });
  }
}
