import {Component, Inject, Input, OnDestroy} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {Subject} from 'rxjs';
import {LoaderService} from '@shared/services/loader.service';

@Component({
  selector: 'app-loader',
  template: `
    <div *ngIf="isLoading | async" class="preloader">
      <div class="preloader-spin"></div>
    </div>
  `,
})
export class LoaderComponent implements OnDestroy {
  isLoading: Subject<boolean> = this.loaderService.isLoading;

  constructor(private router: Router, @Inject(DOCUMENT) private document: Document, private loaderService: LoaderService) {
    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          this.loaderService.setLoading(true);
        } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError
        ) {
          this.loaderService.setLoading(false);
        }
      },
      () => {
        this.loaderService.setLoading(false);
      }
    );
  }

  ngOnDestroy(): void {
    this.loaderService.setLoading(false);
  }
}
