export const logoBase64 = 'data:image/png;base64,' +
  'iVBORw0KGgoAAAANSUhEUgAAAZkAAABbCAMAAACWLAj3AAAAM1BMVEVHcEz////////////////////////////////////////////////////////////////' +
  'P0YphAAAAEHRSTlMA35/PQBAwgL/vYCBwj1Cv3cEy1QAACG5JREFUeNrtXNmS6ygMDWA2r/z/104asJHYnKRz5zJTOg9dXXGCQQe0248HgUAgEAgEAoFAIBAIBA' +
  'KBQCAQCATCfw+7cIciMQxIjHvCShLEcDh+mHEzCWI0SE+MO0gSQyqzJ0idjQYemdlJFIPBRmY4iWJMZeYsyWIsbCczbiJhDAV2MbORMEaCuohxjKQxErQnRfi/l' +
  'KEZCYGT2f/VJI5xsMT437vOguQxDtaYMwu5s4UEMgxMZCSos5UEMgpCNlOc/1BWcxiko2IoqzkUknlZqUgzFOwVYS6U1RwJO8jKMMpqDgQOQv+NijSjKbN4TiZSZ' +
  '+NAITIsZTWHAVZgnLKawyCUZnBs80KRRs6a0jg1LFp/p/q44Lj/ygfcQThnx6NmYs783UBZ2m+5UHlw+WJWcxqzYGD/uv/ilY75xkgiS8isr2U1B2Xma2L5XXT4jd' +
  'Rjob2CdjPEzKcCFc5Z9a3Dh46IeCmrScy0XaP1K/a3NCvbS1lNYuZPE1y6YuqlIs2IzEiTWoDsnG1k7qMDsyFFswjHWppnt9jNk+thnXPsmOGniv2MoDZxaZ7ZukP' +
  'GS0I9ls3PytQO0g4mJY1Dcw7ZTKEhHApw4CzCTQTfc2YmfwvBu/p156IinDMI8ENny5bHz2zPtZ2XwnpYeTPuIKAktE2fmwlrDPaSmyfBEFZnIxzxgjrt9HoGigbM' +
  'acssxMzApJTObAheDEThkiuwI9kMmQF7lTft047m0R7a6sxPTFO0e4hYThzZzQxaQCJACby0Df9CtX0sA44GhJDVe05ImRQiFbJxwP3isgZZ22SGV+OexE1iZrGtu' +
  '6Pdj4C8cp0NrRoXfn42u/bN0NfT43PKNkXE2wkPdKkY4hoBytdH3h1m4HylqAl9wtaiDtvTFBEaOHPd7ttyHrw39NxixvHOLnx+38vJmifSI6eXVJm5xGjgwWDJkp' +
  'gV71kF4np/P3OeHVEwE29ZY+a68ZZ5wH6yVWa2NjP4kK/pJjZjZj7XbV0zfWDKeawFMWnZ5wwTMwZTy86vbre+mWRRSfp57cahdkcofz+TrcbZAfTsaXA0WhmbS9f' +
  'IgUtytUj0cWU8TEqUzLAOM1vhrznjjY/iiBk/bTEFb6b+MEHkNdjsUzgLuhaGXuKyY0ZOAz9LpuPiN2e0N8sdM2HziQW4XOB3UGctUAnCCxM+n8FsxS+a/OzmzJyX' +
  'wtHlMJxPASnPmYkC5xrDFK3nBvfVThYwA7bdXn88OigDO2GiJmik1swgHYCZcwEaK8JwGPgNM0uh9ZCI4NHY4EzgYfJTYjIbYU2SgWvOmEmXdmAjNkzMRc2UmUxZc' +
  '0vgdlR5w7MCzEAfeza1VwroXDvqNDwv4lpQIdJ4ugwHX3PFHBbMrOXD9CtcHWDAgg05AcZk8VyRTvMwuXrJmFmzcsuUbrWWtZgJm6GjGknDX/JiwTpjRt1WgFBUut' +
  'lTMdviGjgLGofBeVqcleqsmKioJDQY+CxprRlqlA0IfC+CcZk2dOF3Z8zITK+u18ZmlSzZhE56JRFzZJkBVgQ4Mkk0aDq+y24FqPEOiNr7IdJZ0Jg2lYXAvDRrBTM' +
  '1pbBW1ZmByhKu2M/CIH2ftr/Jo3LMjM3Xpa8JbJVkzIQN89JIc14XZMuLhr6Zc2JT7aRpo2iiaw6WPW+uM9Fnm1eX+ypnZqol0hT4UJ4rXS4HQ8Zv2JtofH2BGVO9' +
  'oiuhvIFrPVr1S4kPU215a9rOKDfQkn6j4GNq3txxflhjxmQD63tmeC/vecTZbTAW2ODPTIMZ/TtmprYkpGsWyQwyQFNlY4PsDMozVGq9uvNgbjU9ov9NZuawO4N6N' +
  'lG3IfVt/syZ6TAztyPDFTltN8zg3JwYj5meNjvVWTBugZ8dKbOWNrPyj2mzo/3E7IKymjfazC9WG5yyGUSb3XoApzoTYfd5Fg6kzGoewBMriDTfZebOA7CdBiaBYr' +
  'quB5BKIA2q3/YAWM8DeJOZO6/5dAWj+lApXbs/qsm1mGE7yy0fMXPjNe+uE4lolNXses2PPLxeasrjc6/5d8zom0jzMrfRrpoyoyuL2EKmlq6PmLmJNHnvbSYKZQt' +
  '4YUB0XXFVlVM90txfjTR/x0wrO3Pk0Vvce3MrGQ6b63iWnXmbmX52hnWbyxlUdd3sjLLuqqggZma9fyM78zYzfgtNUz+jqYroLaoXW1YOl6xsxN1vmelmNFX/rVkb' +
  'shm9jCZLE1iAnvAlmbBXf5fRfJuZJ88/Nxe9KsBWujtwO2e6JH62Ld6cMpfnmt9m5qoCKOzapsxQ+yEmbIV6VYAgyeVKzKPMrS7n8W4V4C1mguciLDiIV+XMpiKPq' +
  'PwoenBLpaQrRTnE/vgNM4/KiOda2U2LDKoPpcqZKCpnJqucoZKSyStnolc5s9my32dmrlS0O9VmuDiOzA7WJYttlV0/ZaZTbXY3bWUc92q2q80qWzVaIP99tfktZp' +
  'I5h40AzHUL4gvKJu611qFsAan/6FNmOh0a9qYVc88E2O7QQE0Tl1WbkKF9p0PDTo/PmblKn9Dzka2uJiBehpyfUpfAIfjS7ovCXU1H7u+CozDDSankPOm752REtjz' +
  'c1bSA24BGI5A2mw5URps/7WpaHi2ZzdV4bPfC0njPyflgzWa32M4H3GpRSVldQ6DnhvBP46yMrF5ac4HD9kTg1s7GdDv+JTdZs13oRHSMz2ECSUhzaF/k3Ud6Ykdi' +
  'tVgQmwxt1gmY9S3mMtv+Ry/KM/RCxkFhG42xhH8VyrCtYtbpyeUhDogulRk97f+3MRW5ZE3v/R3IqKToIIYkhgTz17GCRNzzCBlHb2EYykf2oYzmzDl6F+MwqKTNy' +
  'PwPQs1RfeKFMIKtQQ+EkVs20rFZT5V20AvlxotsniCXjEAgEAiED/EPMr8uBAr5IJgAAAAASUVORK5CYII=';
