import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@shared/services/authentication.service';
import {takeUntil} from 'rxjs/operators';
import {BasePage} from '@shared/base/base-page';

@Component({
  selector: 'app-referral-url',
  template: ``
})
export class ReferralURLComponent extends BasePage {

  constructor(injector: Injector, private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService) {
    super(injector);
    this.route.params.subscribe((params: any) => {
      if (params.referal) {
        if (params.referal.length >= 5) { // vanity url
          this.authenticationService.searchReferalOrVanity(params.referal).pipe(takeUntil(this.onDestroy$)).subscribe((data: any) => {
            if (!data.error) {
              if (data.available) {
                switch (data.codeType) {
                  case 'vanityLink':
                    data.vanityLink = params.referal;
                    break;
                }
                delete data.error;
                delete data.available;
                delete data.messages;
                localStorage.setItem('referencialLink', this.kryptoService.set(JSON.stringify(data)));
              } else {
                localStorage.removeItem('referencialLink');
              }
              this.router.navigate(['/']);
            }
          });
        } else {
          this.router.navigate(['/auth/login']);
        }
      }
    });
  }
}
