import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { catchErrors } from './catch-error';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  error = false;

  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.authenticationService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.access_token;

    return next.handle(request).pipe(catchError(error => {
      if ([401].indexOf(error.status) !== -1) {
        this.authenticationService.logout();
      } else if ([400].indexOf(error.status) !== -1) {
        if (catchErrors[error.error.messages[0].code]) {
          swal({
            title: 'Something went wrong!',
            text: catchErrors[error.error.messages[0].code],
            type: 'error',
            timer: 0,
            allowOutsideClick: true,
            showConfirmButton: true
          });
        }
      } else {
        this.error = true;
        swal({
          title: error.status === 500 ? 'An error occurred on the server!' : (error.status === 404 ? 'Service not found!' : 'Connection error!'),
          type: 'error',
          timer: 20000,
          showConfirmButton: true
        });

        setTimeout(() => {
          this.error = false;
        }, 1000);
      }

      const e = error.error.message || error.statusText;
      return throwError(e);
    }));
  }
}
