import {BrowserModule, Meta} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {ModalModule} from 'ngx-bootstrap/modal';
import {NgxMaskModule} from 'ngx-mask';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { registerLocaleData} from '@angular/common';
import {ErrorInterceptor} from '@shared/helpers/error.interceptor';
import {JwtInterceptor} from '@shared/helpers/jwt.interceptor';
import en from '@angular/common/locales/en';
import {LoaderComponent} from '@shared/loader/loader.component';
import { ReferralURLComponent } from '@auth/login/referralURL.component';
import { LocationStrategy, Location, PathLocationStrategy } from '@angular/common';
import { MetaModule } from 'ng2-meta';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

registerLocaleData(en, 'en-US');

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    ReferralURLComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    NgxMaskModule.forRoot(),
    ModalModule.forRoot(),
    AccordionModule.forRoot(),
    PopoverModule.forRoot(),
    MetaModule.forRoot(),
  ],
  providers: [Meta,
    {provide: LOCALE_ID, useValue: 'en-US'},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      Location, {provide: LocationStrategy, useClass: PathLocationStrategy},
      { provide: "googleTagManagerId", useValue: "GTM-PC62M5SK" },
      GoogleTagManagerService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
