import {ChangeDetectorRef, Injector, OnDestroy, Type} from '@angular/core';
import {Subject} from 'rxjs';
import {EncrDecrService} from '@shared/helpers/encr-decr.service';
import {AuthenticationService} from '@shared/services/authentication.service';
import {User} from '@shared/models/user';
import {takeUntil} from 'rxjs/operators';
import {Workbook} from 'exceljs';
import * as fs from 'file-saver';
import {logoBase64} from '@shared/base/logoImg';
import {formatDate} from '@angular/common';
import {TypeReport} from '@dashboard/views/admin/dashboard/reports/reports';
import {environment} from '@environment/environment';

export abstract class BasePage implements OnDestroy {
  protected cd: ChangeDetectorRef;
  protected onDestroy$ = new Subject();
  protected kryptoService: EncrDecrService;
  public currentUser: User;
  protected auth: AuthenticationService;
  public today: Date;
  public reportsAdmin = TypeReport;

  /*Table variables*/
  public key = ''; // set default
  public reverse = true; // set default
  public number = 10;
  public max = 0;
  public p;
  public filter;
  public item: any;

  public price: number;
  public commission: number;
  public paymentProcessorFee: number;


  constructor(injector: Injector) {
    $('html, body').animate({scrollTop: 0}, 300);
    this.cd = injector.get<ChangeDetectorRef>(ChangeDetectorRef as Type<ChangeDetectorRef>);
    this.kryptoService = injector.get(EncrDecrService);
    this.auth = injector.get(AuthenticationService);
    this.today = new Date();
    this.price = environment.price;
    this.commission = environment.commission;
    this.paymentProcessorFee = environment.paymentProcessorFee;

    this.auth.currentUser.pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
      this.currentUser = user;
    });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  sort(key) {
    if (key === this.key) {
      this.reverse = !this.reverse;
      if (this.reverse === true) {
        this.key = '';
      }
    } else {
      this.key = key;
      this.reverse = true;
    }
  }

  exportExcel(flag, data, dateStart?, dateEnd?) {
    const header = this.reportsAdmin[flag].fields;

    const array = data.map((el) => {
      const item = [];
      for (const it of this.reportsAdmin[flag].body) {
        const att = it.split('.');
        if (att.length > 1) {
          let object = {} as any;
          let aux = 0;
          for (const at of att) {
            if (aux === 0) {
              object = el[at];
              aux++;
            } else {
              object = object[at];
            }
          }

          item.push(object);
        } else {
          item.push(el[it]);
        }
      }
      return item;
    });

    if (dateStart && dateEnd) {
      this.exportExcelFile(header, array, this.reportsAdmin[flag].label, dateStart, dateEnd);
    } else {
      this.exportExcelFile(header, array, this.reportsAdmin[flag].label);
    }
  }

  exportExcelFile(headers: any[], rows: any[], fileName, dateStart?, dateEnd?) {
    const exportName = fileName + formatDate(this.today, '_MM_dd_yyyy_HH_mm', 'en-US') + '.xlsx' || 'report' + formatDate(this.today, 'MM-dd-yyyy_HH:mm', 'en-US') + '.xlsx';
    const workbook = new Workbook();
    workbook.created = new Date();
    const worksheet = workbook.addWorksheet('report');

    const header = headers;
    const titleRow = worksheet.addRow([fileName]);
    titleRow.font = {name: 'Arial Black', family: 2, size: 16, bold: true, vertAlign: 'superscript', color: {argb: 'F1F1F1F1'}};
    worksheet.addRow([]);

    if (dateStart && dateEnd) {
      worksheet.addRow(['Date Range :   ' + formatDate(dateStart, 'medium', 'en-US') + '  -  ' + formatDate(dateEnd, 'medium', 'en-US')]);
      worksheet.mergeCells('A1:C2');
    } else {
      worksheet.addRow(['Date : ' + formatDate(new Date(), 'medium', 'en-US')]);
      worksheet.mergeCells('A1:C2');
    }

    worksheet.addRow([]);
    worksheet.addRow([]);
    // Add Header Row
    const headerRow = worksheet.addRow(header);
    // tslint:disable-next-line:variable-name
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'b2bcc0'},
        bgColor: {argb: 'b2bcc0'}
      };
      cell.border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};
    });

    // Add Data
    for (const row of rows) {
      worksheet.addRow(row);
    }

    worksheet.columns.forEach((column) => {
      column.width = 40;
    });

    const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
      'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    for (let i = 1; i < worksheet.columns.length; i++) {
      [letters[i] + '1', letters[i] + '2'].map(key => {
        worksheet.getCell(key).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: '524B9A'},
        };
      });
    }

    const logo = workbook.addImage({
      base64: logoBase64, extension: 'png',
    });
    worksheet.addImage(logo,
      {
        tl: {col: rows[0].length - 1 + 0.9999, row: 0.2},
        ext: {width: 160, height: 40},
      }
    );

    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((response) => {
      const blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      fs.saveAs(blob, exportName);
    });
  }
}
