import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ReferralURLComponent} from '@auth/login/referralURL.component';
import { HttpsGuard } from '@shared/helpers/https-guard.guard';

const routes: Routes = [
  {path: '', loadChildren: () => import('@dashboard/dashboard.module').then(m => m.DashboardModule)},
  {path: ':referal', component: ReferralURLComponent},
  {path: 'auth/login', loadChildren: () => import('@auth/auth.module').then(m => m.AuthModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
