import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environment/environment';
import { EncrDecrService } from '@shared/helpers/encr-decr.service';
import { Router } from '@angular/router';
import { catchErrors } from '@shared/helpers/catch-error';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient, private kryptoService: EncrDecrService, private router: Router) {
    if (sessionStorage.getItem('currentUser')) {
      this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(this.kryptoService.get(sessionStorage.getItem('currentUser'))));
    } else {
      this.currentUserSubject = new BehaviorSubject<any>(sessionStorage.getItem('currentUser'));
    }

    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    if (this.currentUserSubject) {
      return this.currentUserSubject.value;
    }
  }

  public setCurrentUserSub(user): any {
    if (user) {
      this.currentUserSubject.next(user);
    }
  }

  login(params) {
    return this.http.post<any>(`${environment.apiUrl}/api/login`, params).pipe(map(data => {
      if (data.user && data.access_token) {
        data.user.access_token = data.access_token;
        sessionStorage.setItem('currentUser', this.kryptoService.set(JSON.stringify(data.user)));
        this.currentUserSubject.next(data.user);
        return data.user;
      } else {
        swal({
          title: 'Access denied!',
          text: 'Incorrect user or password',
          type: 'error',
          timer: 1300,
          showConfirmButton: false
        });
      }

      return false;
    }));
  }

  validateReferenceLink(referenceLink) {
    return this.http.get<any>(`${environment.apiUrl}/api/public/user/validateReferenceLink?referenceLink=${referenceLink}`);
  }

  validateVanityLink(vanityLink) {
    return this.http.get<any>(`${environment.apiUrl}/api/user/searchVanityLink?vanityLink=${vanityLink}`);
  }

  searchReferalOrVanity(vanityLink) {
    return this.http.get<any>(`${environment.apiUrl}/api/public/user/searchReferalOrVanity?code=${vanityLink}`);
  }

  logout() {
    this.http.get<any>(`${environment.apiUrl}/api/logout`).pipe(map(user => { }));
    sessionStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigate(['']);
  }
}

