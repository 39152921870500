export const TypeReport = [
  // Sales Reports
  {
    id: 1,
    label: 'Sales Summary', fields: [
      'Date',
      'Username',
      'Name',
      'Ad Quantity',
      'Processing Fee',
      'Sale Amount',
      'Resident',
      'Payment Type'
    ], body: [
      'registerDateString',
      'users.username',
      'fullName',
      'numberAds',
      'commissionPay',
      'total',
      'users.states.name',
      'payMode'
    ]
  },
  {
    id: 2,
    label: 'Sales Detailed', fields: [
      'Date/Time',
      'Username',
      'Name',
      'Status',
      'Reference Link'
    ], body: [
      'saleDateString',
      'buyerUsername',
      'buyerName',
      'acquiredBy',
      'referenceLink'
    ]
  },
  {
    id: 3,
    label: 'Sales Referred', fields: [
      'Date',
      'Username',
      'Name',
      'Referrer (Name)',
      'Referrer (Username)',
      'Reference Link'
    ], body: [
      'saleDateString',
      'buyerUsername',
      'buyerName',
      'sellerName',
      'sellerUsername',
      'referenceLink'
    ]
  },

  // Check
  {
    id: 4,
    label: 'Checks Requested', fields: [
      'Username',
      'Name',
      'Amount',
      'Date',
      'Payment #',
      'Ads Published'
    ], body: [
      'users.username',
      'fullName',
      'quantity',
      'registerDateString',
      'paymentNumber',
      'numberAds'
    ]
  },
  {
    id: 5,
    label: 'Checks Not Cashed', fields: [
      'Username',
      'Name',
      'Amount',
      'Date',
      'Payment #',
      'Ads Published'
    ], body: [
      'users.username',
      'fullName',
      'quantity',
      'registerDateString',
      'paymentNumber',
      'numberAds'
    ]
  },
  {
    id: 6,
    label: 'Checks Cashed', fields: [
      'Username',
      'Name',
      'Amount',
      'Date',
      'Payment #',
      'State (Recidence)'
    ], body: [
      'users.username',
      'fullName',
      'quantity',
      'registerDateString',
      'paymentNumber',
      'users.states.name'
    ]
  },
  {
    id: 7,
    label: 'Checks Detailed', fields: [
      'Username',
      'Name',
      'Amount',
      'Date',
      'Payment #',
      'Status'
    ], body: [
      'users.username',
      'fullName',
      'quantity',
      'registerDateString',
      'paymentNumber',
      'csvGenerated'
    ]
  },

  // Users
  {
    id: 8,
    label: 'Member Search', fields: [
      'Username',
      'Name',
      'Status',
      'Date Reg\'d'
    ], body: [
      'username',
      'fullname',
      'status',
      'registerDateString'
    ]
  },
  {
    id: 9,
    label: 'Site Activity', fields: [
      'Username',
      'Name',
      'IP',
      'Date (Log  in)'
    ], body: [
      'username',
      'fullname',
      'address',
      'lastLogin'
    ]
  },

  // Ads
  {
    id: 10,
    label: 'Ads', fields: [
      'Ad ID',
      'Username',
      'Name',
      'Ad Title',
      'Date (Published)',
      'State (Published)'
    ], body: [
      'id',
      'username',
      'fullname',
      'title',
      'registerDateString',
      'state'
    ]
  },
  {
    id: 11,
    label: 'Ads Reported', fields: [
      'Ad ID',
      'Username',
      'Name',
      'Ad Title',
      'State (Published)',
      'IP (Reported from)'
    ], body: [
      'id',
      'user.username',
      'fullName',
      'title',
      'state.name',
      'complaint[0].ip'
    ]
  },
  {
    id: 12,
    label: 'Ads Search', fields: [
      'Ad ID',
      'Username',
      'Name',
      'Ad Title',
      'Date (Published)',
      'State (Published)'
    ], body: [
      'id',
      'user.username',
      'fullName',
      'title',
      'registerDateString',
      'state.name'
    ]
  },
  {
    id: 13,
    label: 'Hopper report',
    fields: [
      'Buyer Name',
      'Username',
      'Register Date',
      'Sale Date',
      'Seller Name',
      'Seller Username',
      'State',
      'Status',
      'By'
    ],
    body: [
      'buyerName',
      'buyerUsername',
      'registerDate',
      'saleDate',
      'sellerName',
      'sellerUsername',
      'state',
      'status',
      'acquiredBy',
    ]
  },
  {
    id: 14,
    label: 'Gift code report',
    fields: [
      'Shopping Id',
      'Status',
      'Buyer Username',
      'Buyer Name',
      'Purchase Date',
      'Redeemer Username',
      'Redeemer Name',
      'Redeem Date',
    ],
    body: [
      'shopping_id',
      'status',
      'buyerUsername',
      'buyerName',
      'registerDateString',
      'redeemerUsername',
      'redeemerName',
      'redeemDateString',
    ]
  },
];
